var dom = require('./dom'), doc=dom.doc, Cookie=dom.Cookie, on_load=dom.on_load;
var map = require('./lib').map;
var sprintf = require('./print').sprintf;

class RssMenu {
    constructor() {
        this.feed = {
            blog:  { lead_in: "The latest",       name: "news",  max:  5 },
            forum: { lead_in: "New posts on the", name: "forum", max: 10 }
        };
        this.div = {};
    }

    add_feed(name, rss) {
        this.feed[name].rss = rss;
    }

    set_max(name, max) {
        this.feed[name].max = max;
    }

    just_date(d) {
        return sprintf("%d/%02d/%02d", d.getFullYear(), d.getMonth()+1, d.getDate());
    }

    render_rss(div, feed) {
        if (feed.max) feed.rss.entries.length = Math.min(feed.max, feed.rss.entries.length);

        var _new = true, new_count = 0, sl;

        div.appendChild(doc(["li", { className: "game_section" },
                             ["h2", feed.lead_in+" ",
                              ["a", { href:feed.rss.link }, feed.name],
                              ":"],
                             sl=doc(["div", { className: "gamesublist" }])]));

        for (var e=0; e<feed.rss.entries.length; e++) {
            var item = feed.rss.entries[e];

            var d = new Date();
            d.setTime(item.publish_date && item.publish_date+"000" || Date.parse(item.published));

            if (item.published == Cookie.list["last_seen_"+feed.name])
                _new = false;

            if (_new)
                new_count++;

            sl.appendChild(doc(["li", { className: "rss-entry menu-item" },
                                ["a", {href:item.link, title: item.title.replace(/^.* • /,"")},
                                 ["span", { className: "rss-date" }, this.just_date(d)],
                                 ["span", { className: "rss-title" }, item.title.replace(/^.* • /,"")],
                                 _new ? ["span", { className: "new badge" }, "New"]
                                 : ["span", { className: "new" }, ""]]]));
        }

        return new_count;
    }

    mark_rss_read() {
        this.div.rss_menu.classList.add("seen");
        for (let feed of Object.values(this.feed))
            Cookie.set(`last_seen_${feed.name}`, feed.rss.entries[0].published);
    }

    auto_mark_rss_read(menu) {
        // Desktop: Set a timer when people open the menu
        let timer;
        this.div.rss_menu.addEventListener("mouseenter", (e) => {
            timer = dom.timeout(3000, () => {
                this.mark_rss_read();
            });
        });
        this.div.rss_menu.addEventListener("mouseleave", (e) => dom.cancel_timeout(timer));

        // Mobile: Hamburger menu
        require('./menu').menu_close_hook(this.div.rss_menu, () => {
            this.mark_rss_read();
        });
    }

    configure(rss_list_el, rss_menu_el, new_rss_el) {
        this.div = { rss_list:rss_list_el, rss_menu:rss_menu_el, new_rss:new_rss_el };
    }

    render() {
        var new_count = 0
            + this.render_rss(this.div.rss_list, this.feed.blog)
            + this.render_rss(this.div.rss_list, this.feed.forum);

        if (new_count) {
            doc.clear(this.div.new_rss).appendChild(doc.text(new_count));
            this.auto_mark_rss_read();
        } else
            this.div.rss_menu.classList.add("seen");
    }
}

var rss_menu = new RssMenu();
on_load(() => {
    rss_menu.configure(doc.id("rss-list"),
                       doc.id("rss-menu"),
                       doc.id("new-rss"));
    rss_menu.render();
});

module.exports = {
    add_feed: rss_menu.add_feed.bind(rss_menu),
    set_max: rss_menu.set_max.bind(rss_menu)
};
